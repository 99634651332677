import {isRegExp} from "eslint-plugin-vue/lib/utils/regexp";

export default {

    /**
     * activate debugging for a context. Gets a REGEX
     * @param   context
     */
    // eslint-disable-next-line
    setDebugging(context) {
        if(isRegExp("/" + context + "/"))
        {
            if(!this.isContextWatched(context))
            {
                let debugContexts = this.getContexts();
                debugContexts.push(context);
                this.storeContexts(debugContexts);
            }
            console.info('debugging enabled for context "' + context + '". All actually watched contexts: ', this.getContexts());
        }
        else
        {
            console.error('Given context is not a valid regular expression');
        }
    },

    unsetDebugging(context){

        let debugContexts =  this.getContexts();
        let index = debugContexts.indexOf(context);
        if(index >= 0)
        {
            debugContexts.splice(index, 1);
        }
        this.storeContexts(debugContexts);
        console.info('debugging disabled for context /' + context + '/. All actually watched contexts: ', this.getContexts());
    },

    clearDebugging() {
        this.storeContexts([]);
        console.info('debugging disabled completely');
    },

    moDebug(context, ...message) {
        if(this.isContextWatched(context)) {
            console.log(context + "\n", ...message);
        }
    },

    moError(context, ...message) {
        if(this.isContextWatched(context)) {
            // @todo
        }
        console.error(context + "\n", ...message);
    },

    moWarn(context, ...message) {
        if(this.isContextWatched(context)) {
            // @todo
        }
        console.warn(context + "\n", ...message);
    },

    moInfo(context, ...message) {
        if(this.isContextWatched(context)) {
            // @todo
        }
        console.info(context + "\n", ...message);
    },

    isContextWatched(context) {
        let debugContexts = this.getContexts();
        for(const abonnement of debugContexts)
        {
            let regex = new RegExp("/" + abonnement + "/");
            if(context.match(regex))
            {
                return true;
            }
        }

        return false;
    },

    getContexts() {
        let debugContexts = JSON.parse(localStorage.getItem('debugContexts'));
        if(!debugContexts)
        {
            debugContexts = [];
        }
        return debugContexts;
    },

    storeContexts(contexts) {
        localStorage.setItem('debugContexts', JSON.stringify(contexts));
    }
}