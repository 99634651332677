import axios from "axios";
import appConfig from "@/app-config";
import appInfo from "@/app-info";
import moDebugger from "@/utils/moDebugger";
import auth from "@/auth";
import router from "@/router";
import notify from "devextreme/ui/notify";


export default {

    async get(route, additional_headers, onSuccess, onFailure)
    {
        return this.request('get', route, null, additional_headers, onSuccess, onFailure);
    },


    async post(route, data, additional_headers, onSuccess, onFailure)
    {
        return this.request('post', route, data, additional_headers, onSuccess, onFailure);
    },


    async put(route, data, additional_headers, onSuccess, onFailure)
    {
        return this.request('put', route, data, additional_headers, onSuccess, onFailure);
    },


    async patch(route, data, additional_headers, onSuccess, onFailure)
    {
        return this.request('patch', route, data, additional_headers, onSuccess, onFailure);
    },


    async delete(route, data, additional_headers, onSuccess, onFailure)
    {
        return this.request('delete', route, data, additional_headers, onSuccess, onFailure);
    },


    headers(additional_headers)
    {
        return {
            'Authorization': 'Bearer ' + appConfig.token,
            'ylvi-client': 'Frontend ' + appInfo.title + ' V' + appInfo.version + ' ' + appInfo.buildDate,
            ...additional_headers
        }
    },


    async request(method, route, data, additional_headers, onSuccess, onFailure)
    {
        moDebugger.moDebug('utils/apirequest/request/init', method, route, additional_headers, onSuccess, onFailure);

        const headers = this.headers(additional_headers);

        var url = appConfig.apiHost + route; // @todo remove trailing slashes etc

        return axios.request({
                url: url,
                method: method,
                data: data,
                headers: headers,
            })
            .then(response =>
            {
                moDebugger.moDebug('utils/apirequest/request/then', response);
                if (response.status >= 200 && response.status < 300 && onSuccess)
                {
                    let data = [];
                    if(method === 'get' && response.data?.d?.results)
                    {
                        data = response.data?.d?.results;
                    }
                    else
                    {
                        data = response.data;
                    }

                    return onSuccess(response, data);
                }
            })
            .catch((e) =>
            {
                console.log(e);
                moDebugger.moWarn('utils/apirequest/request/catch/Error', e);

                let result;
                if(onFailure)
                {
                    result = onFailure(e);
                }

                // @todo handle expired token
                if(e.response && e.response.status === 401 && route !== 'auth/logout' && route !== 'forgotPassword') // @todo get the information requiresAuth from router
                {
                    notify('Ihre Sitzung ist aufgrund zu langer nicht-Nutzung abgelaufen und Sie wurden daher zur Sicherheit automatisch abgemeldet.',
                        "Error", 5000);
                    auth.logOut().then();
                }
                else
                {
                    moDebugger.moDebug('utils/apirequest/request/catch/response-code', e.response, route);
                }

                return result;
            });
    }
};