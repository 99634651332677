import auth from "./auth";
import {createRouter, createWebHashHistory} from "vue-router";
import defaultLayout from "./layouts/side-nav-inner-toolbar";
import simpleLayout from "./layouts/single-card";
import appConfig from "@/app-config";

import {loadMessages, locale} from "devextreme/localization";
import deMessages from "devextreme/localization/messages/de.json";
import ylviMessages from "@/ylvi_lng.json";
import apirequest from "@/utils/apirequest";

//import nav from "./app-intern-navigation";
import nav2 from "./app-kaestli-navigation";
import moDebugger from "@/utils/moDebugger";

function loadView(view) {
    return () => import (`./views/${view}.vue`);
}

// eslint-disable-next-line no-unused-vars
function addRouteFromMenuItem(items) {
    if (items) {
        items.forEach((item) => {
            if (item.path && item.path !== '') {
                // remove the query params from the route
                var path_splitted = item.path.split('?');
                var path = path_splitted[0];

                // extract the query paramters and add them in vue style
                // @todo this code could be optimised because it looks pretty complicated
                var query_params  = {};
                if(path_splitted.length > 1 && path_splitted[1].length > 0)
                {
                    path_splitted[1].split('&').forEach(function (q, i) {
                        var param_splitted = q.split('=');
                        query_params[param_splitted[0]] = param_splitted[1];
                    });
                }
                moDebugger.moDebug('router/addRouteFromMenuItem/addRoute', path, query_params);

                router.addRoute({
                    component: loadView(path.replace('/', '')),
                    name: path.replace('/', ''),
                    path: path,
                    query: query_params,
                    meta: {
                        requiresAuth: true,
                        layout: defaultLayout,
                    }
                });
            }
            if (item.items) addRouteFromMenuItem(item.items);
        });
    }
}

// eslint-disable-next-line no-unused-vars
export async function getMenu(menu_type = 'FRONTEND', callback) {

    await apirequest.get('v1/menu/' + menu_type, {}, callback);
}

loadMessages(deMessages);
loadMessages(ylviMessages);
locale(appConfig.language);

let version = localStorage.getItem('version');
if (version !== appConfig.version) {
    let token = localStorage.getItem('token')
    localStorage.clear();
    localStorage.setItem('token', token);
    localStorage.setItem('version', appConfig.version);
}

const home = 'home';
const router = new createRouter({
    routes: [
        {
            path: "/",
            redirect: home
        },

        {
            path: "/:pathMatch(.*)*",
            redirect: home
        },

        {
            path: "/home",
            name: "home",
            meta: {
                requiresAuth: true,
                layout: defaultLayout
            },
            component: loadView("home/home")
        },
        {
            path: "/authentication",
            name: "login-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Anmeldung"
            },
            component: loadView("authentication/login-form")
        },
        /*{
            path: "/createAccount",
            name: "create-account-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Zugang beantragen"
            },
            component: loadView("authentication/create-account/create-account-form")
        },//*/
        {
            path: "/forgotPassword",
            name: "forgot-password-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Kennwort zurücksetzen"
            },
            component: loadView("authentication/forgot-password/forgot-password-form")
        },
        {
            path: "/resetPassword",
            name: "reset-password-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Kennwort zurücksetzen"
            },
            component: loadView("authentication/reset-password/reset-password-form")
        },
        {
            path: "/changePassword",
            name: "change-password-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Kennwort ändern"
            },
            component: loadView("authentication/change-password-form")
        },
        {
            path: "/verifyEmail",
            name: "verify-email",
            meta: {
                requiresAuth: false,
                layout: simpleLayout
            },
            component: loadView("ylvi/verify-email")
        },
        {
            path: "/reporting/designer",
            name: "reporting/designer",
            meta: {
                requiresAuth: true,
                layout: simpleLayout
            },
            component: loadView("reporting/designer")
        },
        {
            path: "/order/order-view",
            name: "order/order-view",
            meta: {
                requiresAuth: true,
                layout: defaultLayout,

            },
            component: loadView("order/order-view")
        },
    ],
    history: createWebHashHistory()
});

router.beforeEach(async (to, from, next) => {

    moDebugger.moDebug('router/beforeEach/init', to, from);

    // @todo do this after login
    if (from.name === 'login-form' &&
        to.name !== 'reset-password-form' &&
        to.name !== 'forgot-password-form' &&
        to.name !== 'verify-email')
    {

        let promise_main_menu = getMenu('FRONTEND', function (response, data) {
            appConfig.menu = data;
            addRouteFromMenuItem(appConfig.menu);
            document.treeView.option('items', appConfig.menu);
        });

        let promise_top_menu = getMenu('FRONTEND_TOP',
            function (response, data) {
                moDebugger.moDebug('components/header-toolbar/data/getMenu/onSuccess', response, data);
                for (let entry of data) {
                    switch (entry.key) {
                        case 'NEW_TIMETRACK_BUTTON':
                            appConfig.workTimeBtnEnabled = false; //auth.hasRight(auth.MoRights.TimetracksCreate);
                            break;

                        case 'NEW_PROJECTTRACK_BUTTON':
                            appConfig.projectTimeBtnEnabled = auth.canTrackTime();
                            break;

                        case 'AUTH_MENU':
                            appConfig.authmenu_enabled = true;
                            break;
                    }
                }
            },
            function (e) {
                console.warn('loading top menu entries failed');
                moDebugger.moError('components/header-toolbar/data/getMenu/onFailure', e);
            });

        let promise_current_timetrack;
        if(auth.canTrackTime())
        {
            promise_current_timetrack = apirequest.get('v1/timetracks/for/own/current', {},
                (response, data) => {
                    appConfig.timetrackingRecord = data;
                    appConfig.timetrackingTimer  = null; // will be resetted on mount
                },
                (error) => {
                    // no time track found
                });
        }
        else
        {
            promise_current_timetrack = new Promise((resolve)=>{ resolve(); }); // empty Pseudo Promise
        }

        await Promise.all([promise_main_menu, promise_top_menu, promise_current_timetrack]);
    }

    if (to.name === "login-form" && auth.loggedIn()) {
        next({name: "home"});
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!auth.loggedIn()) {
            next({
                name: "login-form",
                query: {redirect: to.fullPath}
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
