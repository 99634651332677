<template>
    <input type="text"     style="visibility: hidden;" autocomplete="false" /><!-- hack to prevent browser from auto filling field password1 -->
    <input type="password" style="visibility: hidden;" autocomplete="false" /><!-- hack to prevent browser from auto filling field password1 -->
    <DxForm width="100%" :form-data="formData" :disabled="loading">
        <DxLoadPanel v-model:visible="loading"/>

        <DxItem data-field="password1"
                :editor-options="{editorType:'dxTextBox', mode: 'password'}">
            <DxLabel :text="formatMessage('cpPassword1')"/>
            <DxRequiredRule :message="formatMessage('RequiredField')"/>
            <DxCustomRule :validation-callback="__validatePassword"
                          :message="formatMessage('cpInvalid')"
                          :reevaluate="true"/>
        </DxItem>
        <DxItem data-field="password2"
                :editor-options="{editorType:'dxTextBox', mode: 'password', onEnterKey: (e) => {this.setNewPw()}}">
            <DxLabel :text="formatMessage('cpPassword2')"/>
            <DxRequiredRule :message="formatMessage('RequiredField')"/>
            <DxCustomRule :validation-callback="__comparePasswords"
                          :message="formatMessage('cpNotEqual')"
                          :reevaluate="true"/>
        </DxItem>

        <DxItem :hidden="loading">
            <ul>
                <li :class="getRuleClass(formData.minMaxValid)">
                    {{ formatMessage('ruleLength', this.formData.ruleMinLength.toString(), this.formData.ruleMaxLength.toString()) }}
                </li>
                <li :class="getRuleClass(formData.digitValid)" :hidden="!formData.ruleDigit">
                    {{ formatMessage('ruleDigit') }}
                </li>
                <li :class="getRuleClass(formData.upperLowerValid)" :hidden="!formData.ruleUpperLower">
                    {{ formatMessage('ruleUpperLower') }}
                </li>
                <li :class="getRuleClass(formData.specialValid)" :hidden="!formData.ruleSpecial">
                    {{ formatMessage('ruleSpecial') }}
                </li>
                <li :class="getRuleClass(formData.allowedCharsValid)">
                    {{ formatMessage('ruleAllowedChars') }}
                    <br>
                    <code>{{ this.formData.ruleAllowedCharsLabel.toString() }}</code>
                </li>
                <li :hidden="formData.ruleNoRepeats === 0">
                    {{ formatMessage('ruleNoRepeats', this.formData.ruleNoRepeats.toString()) }}
                </li>
            </ul>
        </DxItem>

    </DxForm>
</template>

<script>
import DxValidator, {DxCustomRule, DxRequiredRule} from "devextreme-vue/validator";
import DxForm, {DxButtonItem, DxItem, DxLabel} from "devextreme-vue/form";
import {formatMessage, loadMessages} from "devextreme/localization";
import DxLoadPanel from "devextreme-vue/load-panel";
import appInfo from "@/app-info";
import appConfig from "@/app-config";
import resetPasswordMessages from "@/views/authentication/reset-password/reset-password-lng.json";
import apirequest from "@/utils/apirequest";
import moDebugger from "@/utils/moDebugger";
import notify from "devextreme/ui/notify";
import utils from "@/utils/utils"; // @todo
import auth from "@/auth";

export default {

    components: {
        DxForm,
        DxItem,
        DxLabel,
        DxButtonItem,
        DxLoadPanel,
        DxRequiredRule,
        DxValidator,
        DxCustomRule
    },

    mixins: [],

    props: {
        componentName: String,

        token: String, // needed because we use this form also without authorization but token-GET param

        onSaved: {
            type: Function
        },

        onError: {
            type: Function
        }
    },

    data() {
        const loading = false;
        const formData = {
            password1: "",
            password2: "",
            ruleMinLength: 3,
            ruleMaxLength: 255,
            ruleDigit: false,
            ruleUpperLower: false,
            ruleSpecial: false,
            ruleAllowedChars: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
            ruleAllowedSpecials: "",
            ruleAllowedCharsLabel: "0-9 A-Z",
            ruleNoRepeats: 0,
            minMaxValid: null,
            digitValid: null,
            upperLowerValid: null,
            specialValid: null,
            allowedCharsValid: null,
            formValid: false
        }

        return { loading, formData };
    },

    computed: {
        appInfo() {
            return appInfo
        },
        appConfig() {
            return appConfig
        }
    },

    created() {
        loadMessages(resetPasswordMessages);

        if(!this.token)
        {
            if(this.onError)
            {
                this.onError(1);
            }
        }
        else
        {
            this.setLoading(true);
            this.fetchRules();
            this.setLoading(false);
        }
    },

    methods: {

        formatMessage,

        fetchRules()
        {
            const me = this;

            apirequest.get('v1/renew_password/rules',
                {
                    'Authorization': 'Bearer ' + me.token
                },
                function (response, data)
                {
                    const allowed = response.data?.allowed_chars;
                    let allowed_label = allowed.replaceAll('0123456789', '0-9 ');
                    allowed_label = allowed_label.replaceAll('ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 'A-Z ');
                    const allowed_specials = allowed.replaceAll(/[0-9A-Za-z]/gi, '');

                    me.formData.ruleMinLength         = response.data?.min;
                    me.formData.ruleMaxLength         = response.data?.max;
                    me.formData.ruleDigit             = response.data?.digit;
                    me.formData.ruleUpperLower        = response.data?.upperlower;
                    me.formData.ruleSpecial           = response.data?.special;
                    me.formData.ruleAllowedChars      = allowed;
                    me.formData.ruleAllowedSpecials   = allowed_specials;
                    me.formData.ruleAllowedCharsLabel = allowed_label;
                    me.formData.ruleNoRepeats         = response.data?.no_repeats;
                },
                function (e)
                {
                    moDebugger.moError("views/authentication/reset-password/fetchRules/Error", e);
                    if(e.response?.status === 401)
                    {
                        notify(formatMessage('cpTokenExpired'), "Error", 5000);
                        if(me.onError)
                        {
                            me.onError(2, e);
                        }
                    }
                })
        },

        __validatePassword(e)
        {
            return this.validatePassword(e.value);
        },

        validatePassword(password)
        {
            moDebugger.moDebug("views/authentication/reset-password/validatePassword/init", password);

            const {ruleMinLength, ruleMaxLength, ruleDigit, ruleUpperLower, ruleSpecial,
                ruleAllowedSpecials, ruleAllowedChars} = this.formData;

            let valid = true;

            if(password.length < ruleMinLength || password.length > ruleMaxLength)
            {
                valid = false;
                this.formData.minMaxValid = false;
            }
            else
            {
                this.formData.minMaxValid = true;
            }

            if(ruleDigit && !password.match(/[0-9]/))
            {
                valid = false;
                this.formData.digitValid = false;
            }
            else
            {
                this.formData.digitValid = true;
            }

            if(ruleUpperLower && (!password.match(/[A-Z]/) || !password.match(/[a-z]/)))
            {
                valid = false;
                this.formData.upperLowerValid = false;
            }
            else
            {
                this.formData.upperLowerValid = true;
            }

            const patternSpecial = '[' + utils.moEscapeRegExp(ruleAllowedSpecials) + ']';
            if(ruleSpecial && !password.match(new RegExp(patternSpecial, 'gi')))
            {
                valid = false;
                this.formData.specialValid = false;
            }
            else
            {
                this.formData.specialValid = true;
            }

            const patternAllowed = '[^' + utils.moEscapeRegExp(ruleAllowedChars) + ']';
            if(password.match(new RegExp(patternAllowed, 'gi')))
            {
                valid = false;
                this.formData.allowedCharsValid = false;
            }
            else
            {
                this.formData.allowedCharsValid = true;
            }

            this.formData.formValid = valid;

            return valid;
        },

        __comparePasswords(e)
        {
            const {password1} = this.formData;

            return this.comparePasswords(password1, e.value);
        },

        comparePasswords(password1, password2)
        {
            return password1 === password2;
        },

        setNewPw()
        {
            const {password1, password2} = this.formData;
            const me = this;
            me.setLoading(true);

            if(!this.validatePassword(password1))
            {
                notify(formatMessage('cpInvalid'), "error", 5000);
                me.setLoading(false);
                return false;
            }

            if(!this.comparePasswords(password1, password2))
            {
                notify(formatMessage('cpNotEqual'), "error", 5000);
                me.setLoading(false);
                return false;
            }

            apirequest.put('v1/renew_password', {
                    "password": password1
                },
                {
                    'Authorization': 'Bearer ' + me.token
                },
                function (response, data) {
                    notify(formatMessage('cpNewPwSet'), "success", 5000);

                    if(me.onSaved)
                    {
                        auth.logOut();
                        me.onSaved();
                    }
                },
                function (e) {
                    moDebugger.moError("views/authentication/reset-password/setNewPw/Error", e);

                    if(e?.response?.data?.message.startsWith('The given password was already used'))
                    {
                        notify(formatMessage('cpNewPwRepeat'), "error", 5000);
                    }
                    else
                    {
                        notify(formatMessage('cpNewPwError'), "error", 5000);
                    }
                    me.setLoading(false);

                    if(me.onError)
                    {
                        me.onError(3, e);
                    }
                });
        },

        setLoading(loading)
        {
            this.loading = loading;
        },

        getRuleClass(value)
        {
            if(value !== null)
            {
                return value ? 'rulematch' : 'rulebreak';
            }
            else
            {
                return '';
            }
        },

        isValid()
        {
            return this.formData.formValid;
        }

    }
}
</script>

<style>
    .rulematch {
        color: darkgreen;
    }

    .rulebreak {
        color: red;
    }
</style>