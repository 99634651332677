<template>
    <header class="header-component">
        <DxToolbar class="header-toolbar" id="header-toolbar">
            <DxItem :visible="menuToggleEnabled" location="before" css-class="menu-button">
                <DxButton icon="menu" styling-mode="text" @click="toggleMenuFunc"/>
            </DxItem>
            <DxItem :visiblex="!menuToggleEnabled" css-class="header-title dx-toolbar-label" location="before">
                <div class="header-title logo"><img height="34" alt="logo" width="58" src="assets/mO_Initiale_RGB.svg"></div>
            </DxItem>


            <!--
            we combine it in the project time tracker
            <DxItem location="after" :visible="workTimeBtnEnabled">
                <DxButton
                    ref="workTimeBtn"
                    text="00:00:00"
                    icon="assets/tools-filled-time.svg"
                    :hint="formatMessage('cpRecordWorkTime')"
                    @click="onWorkTimeClick"
                />
            </DxItem>
            -->
            <DxItem location="after" :visible="projectTimeBtnEnabled">
                <DxButton
                    ref="projectTimeBtn"
                    text="00:00:00"
                    icon="assets/money-time.svg"
                    type="normal"
                    :hint="formatMessage('cpRecordProjectTime')"
                    @click="onTimeTrackingBtnClick"
                />
            </DxItem>
            <DxItem location="after" :visible="authmenu_enabled">
                <DxDropDownButton
                    :items="[
                        // @todo make dynamic
                        {id:1, name:formatMessage('cpChangePassword'), icon:'assets/password-user-filled.svg'},
                        {id:2, name:formatMessage('cpLogout'), icon:'assets/button-on-off.svg'}
                     ]"
                    :drop-down-options="{ width: 210 }"
                    :text="userIdent ? userIdent : 'noName'"
                    styling-mode="text"
                    icon="assets/work-user-filled.svg"
                    display-expr="name"
                    key-expr="id"
                    @item-click="onUserMenuClick"
                />
            </DxItem>
        </DxToolbar>
    </header>

    <ProjectTimePopup
        :visible="projectTimeVisible"
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"
        :width="400"
        :height="450"
        :title="formatMessage('cpNewProjectEntry')">
        <DxForm :col-count="1" :form-data="projectTimeFormData">
            <DxSimpleItem
                data-field="business_partner_id"
                editor-type="dxSelectBox"
                ref="project_business_partner_id_input"
                :editor-options="{dataSource:bsStore, valueExpr:'id' , displayExpr:'displayname'}"
            >
                <DxLabel :text="formatMessage('cpCustomer')"></DxLabel>
                <!-- @todo we need a clear button -->
            </DxSimpleItem>
            <DxSimpleItem data-field="project_id" editor-type="dxSelectBox"
                          ref="project_project_id_input"
                          :editor-options="{dataSource:projectStore, valueExpr:'id' , displayExpr:'displayname'}">
                <DxLabel :text="formatMessage('cpProject')"></DxLabel>
                <!-- @todo we need a clear button -->
            </DxSimpleItem>
            <DxSimpleItem data-field="notes" :col-span="2" editor-type="dxTextArea"
                          :editor-options="{minHeight:150, autoResizeEnabled: true, maxLength:4096}">
                <DxLabel :text="formatMessage('cpNotes')"></DxLabel>
            </DxSimpleItem>
        </DxForm>
        <DxToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            :options="{text: formatMessage('actSave')}"
            @click="onTimeTrackingWindowSaveClick"
        />
        <DxToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            :options="{text:  formatMessage('actCancel')}"
            @click="onTimeTrackingWindowCancelClick"
        />
    </ProjectTimePopup>
    <ChangePWPopup
        v-model:visible="changePasswordVisible"
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="true"
        :show-title="true"
        :width="320"
        :height="500"
        :title="formatMessage('cpChangePassword')">

        <ChangePasswordForm
            component-name="changepwform"
            :token="token"
            :on-saved="onPwSaved"
            ref="changepwform"
        />
        <DxToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="center"
            :options="{text: formatMessage('actSave')}"
            @click="onChangePWClick"
        />
        <DxToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="center"
            :options="{text:  formatMessage('actCancel')}"
            @click="onCancelClick"
        />
    </ChangePWPopup>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
import auth from "../auth";
import appConfig from "../app-config";
import {useRouter, useRoute} from 'vue-router';
import {DxDropDownButton} from "devextreme-vue";
import {confirm} from "devextreme/ui/dialog"
import {DxPopup as ChangePWPopup, DxPopup as ProjectTimePopup, DxToolbarItem} from "devextreme-vue/popup";
import {DxForm, DxSimpleItem, DxLabel} from "devextreme-vue/form";
import {alert} from "devextreme/ui/dialog";
import {formatDate, formatMessage} from "devextreme/localization";
import axios from "axios";
import "devextreme/data/odata/store";
import ChangePasswordForm from "@/components/change-password-form.vue";
import router, {getMenu} from "@/router";
import moDebugger from "@/utils/moDebugger";
import odataStore from "@/utils/odata-store";
import apirequest from "@/utils/apirequest";

export default {

    components: {
        ChangePasswordForm,DxLabel,
        DxButton,
        DxToolbar,
        DxItem,
        DxDropDownButton,
        DxToolbarItem,
        ChangePWPopup,
        ProjectTimePopup,
        DxForm,
        DxSimpleItem
    },

    props: {
        menuToggleEnabled: Boolean,
        title: String,
        toggleMenuFunc: Function,
        logOutFunc: Function
    },

    setup() {},

    data() {
        const router = useRouter();
        const route = useRoute();

        let workTimeBtnEnabled = appConfig.workTimeBtnEnabled;
        let projectTimeBtnEnabled = appConfig.projectTimeBtnEnabled;
        let authmenu_enabled = appConfig.authmenu_enabled;

        let userIdent = appConfig.userIdent;

        return {
            userIdent,
            changePasswordVisible: false,
            projectTimeVisible: false,
            token: appConfig.token,
            formData: {pw: '', retypedPw: ''},
            projectTimeFormData: {business_partner_id: null, project_id: null, notes: ''},
            router,
            route,
            appConfig,
            workTimeBtnEnabled, projectTimeBtnEnabled, authmenu_enabled,

            projectStore: odataStore.createStore({endPoint: 'v1/projects'}),

            bsStore: odataStore.createStore({endPoint: 'v1/businesspartner'})
        };
    },

    mounted() {
        setTimeout(this.updateTimeTracking, 100);
    },

    methods: {
        formatMessage,

        onUserMenuClick(e) {
            const me = this;

            switch (e.itemData.id) {
                case 1:
                    this.formData = {pw: '', retypedPw: ''}
                    this.changePasswordVisible = true;
                    break;
                case 2:
                    confirm(formatMessage('msgWantLogout'), formatMessage('cpLogout')).then((dialogResult) => {
                        if (dialogResult) {
                            auth.logOut();
                            router.push({
                                path: "/authentication",
                                query: {redirect: "/home"}
                            });
                        }
                    });
                    break;
            }
        },

        onChangePWClick: async function () {
            let changepw = this.$refs.changepwform;
            changepw.setNewPw();
        },

        onPwSaved() {
            this.changePasswordVisible = false;
        },

        onCancelClick() {
            this.changePasswordVisible = false;
        },

        onTimeTrackingBtnClick()
        {
            if (!this.isTimeTracking())
            {
                // open modal window to set parameters of the time tracking entry
                this.projectTimeVisible = true;
            }
            else
            {
                this.stopTimeTracking();
            }
        },

        onTimeTrackingWindowSaveClick()
        {
            if (!this.isTimeTracking())
            {
                this.startTimeTracking();
            }

            this.updateTimeTracking();
        },

        onTimeTrackingWindowCancelClick()
        {
            this.projectTimeVisible = false;
            this.updateTimeTracking();
        },

        isTimeTracking()
        {
            return appConfig.timetrackingRecord !== null;
        },

        startTimeTracking()
        {
            const {business_partner_id, project_id, notes} = this.projectTimeFormData;
            this.startTimeEntry('PROJEKT', 'PROJEKT', new Date(), business_partner_id, project_id, notes);
            // @todo type and displayname have to be set dynamically
        },

        stopTimeTracking()
        {
            this.stopTimeEntry();
        },

        updateTimeTracking() {
            if(this.$refs && this.$refs.projectTimeBtn) // on startup the button is not available
            {
                if (this.isTimeTracking())
                {
                    if(appConfig.timetrackingTimer === null)
                    {
                        appConfig.timetrackingTimer = setInterval(this.projectTimerEvent, 1000);
                    }
                    this.$refs.projectTimeBtn.instance.option('type', 'success');
                }
                else
                {
                    if(appConfig.timetrackingTimer !== null)
                    {
                        clearInterval(appConfig.timetrackingTimer);
                    }
                    appConfig.timetrackingTimer  = null;

                    appConfig.timetrackingRecord = null;

                    this.$refs.projectTimeBtn.instance.option('text', '00:00:00');
                    this.$refs.projectTimeBtn.instance.option('type', 'normal');
                }

                this.projectTimeVisible = false;
            }
            else
            {
                setTimeout(this.updateTimeTracking, 100); // try again later
            }
        },

        projectTimerEvent()
        {
            if(this.isTimeTracking() && this.$refs && this.$refs.projectTimeBtn)
            {
                const date = new Date(null);
                date.setSeconds(Math.abs(new Date() - new Date(appConfig.timetrackingRecord.track_started_at)) / 1000);
                this.$refs.projectTimeBtn.instance.option('text', date.toISOString().slice(11, 19));
            }
        },

        startTimeEntry(displayName, trackType, startTime, businessPartnerId, projectId, notes)
        {
            if(!this.isTimeTracking())
            {
                const startTimeStr = startTime.toISOString();
                apirequest.put("v1/timetracks",
                    {
                        "displayname": displayName,
                        "business_partner_id": businessPartnerId,
                        "project_id": projectId,
                        "notes": notes,
                        "track_started_at": startTimeStr,
                        "track_type": trackType,
                        "employee_id": appConfig.employeeId,
                    },
                    [],
                    (response, data) =>
                    {
                        appConfig.timetrackingRecord = data;

                        this.updateTimeTracking();
                    },
                    () => {
                        return false;
                    });
            }
        },

        stopTimeEntry()
        {
            if(this.isTimeTracking())
            {
                const dt = new Date();
                apirequest.patch("v1/timetracks(" + appConfig.timetrackingRecord.id + ")", {
                        "track_ended_at": dt.toISOString(),
                        "notes": ""
                    },
                    {'last-update-ts': (new Date(appConfig.timetrackingRecord.updated_at)).toISOString()},
                    () => {
                        appConfig.timetrackingRecord = null;

                        this.updateTimeTracking();
                    },
                    () => {
                        return false;
                    });
            }
        }
    },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

.header-component {
    flex: 0 0 auto;
    z-index: 1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);

    .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
        color: $base-accent;
    }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
    padding: 0 20px;

    .screen-x-small & {
        padding: 0 20px;
    }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
    width: $side-panel-min-width;
    text-align: center;
    padding: 0;
}

.header-title .DxItem-content {
    padding: 0;
    margin: 0;
}

.header-title .logo {
    padding-left: 15px;
    padding-top: 2px;
}

@media (max-width: 599px) {
    .header-title .logo {
        padding-left: 0;
        padding-top: 2px;
    }
}
</style>


