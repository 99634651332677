import appConfig from "@/app-config";
import {formatDate, formatMessage} from "devextreme/localization";
import apirequest from "@/utils/apirequest";
import auth from "@/auth";
import router from "@/router";
import moDebugger from "@/utils/moDebugger";
import notify from "devextreme/ui/notify";

export default {
    createStore(config) {
        moDebugger.moDebug('utils/odata-store/init', config);

        config.sort   = config.sort   || 'displayname'
        config.key    = config.key    || 'id';
        config.rights = config.rights || [];
        config.filter = config.filter || null;

        // check if rights are granted with the current token
        for (const right_id of config.rights) {
            if(!auth.hasRight(right_id))
            {
                moDebugger.moWarn('utils/odata-store/missing_right', 'store cannot be used because right ' + right_id + ' is not granted');
                return {hasRight: false};
            }
        }

        config.headers = apirequest.headers({
            'last-update-ts': formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            ...config.headers
        });

        return {
            sort: config.sort,
            key: config.key,
            hasRight: true,
            store: {
                type: 'odata',
                key: config.key,
                url: appConfig.apiHost + config.endPoint,
                beforeSend: (e) => {
                    moDebugger.moDebug('utils/odata-store/beforeSend', e);
                    e.headers = config.headers;

                    switch(e.method.toUpperCase())
                    {
                        case 'MERGE':
                        case 'PATCH':
                            e.method = 'PATCH';

                            if(config['patchUrl'])
                            {
                                let endpoint = e.url.replace(appConfig.apiHost + config.endPoint, '');
                                let id       = endpoint.match(/([0-9]+)/)[0]; // keine Ahnung warum aber hierbei kommt immer ein Array mit 2 Treffern zurück

                                // wir gehen davon aus, dass die id beim patch immer am Ende der URL steht.
                                e.url = appConfig.apiHost + config['patchUrl'] + id;
                            }
                            break;

                        case 'POST':
                            if(config['postUrl'])
                            {
                                e.url = appConfig.apiHost + config['postUrl'];
                            }
                            break;

                        case 'DELETE':
                            if(config['deleteUrl'])
                            {
                                let endpoint = e.url.replace(appConfig.apiHost + config.endPoint, '');
                                let id       = endpoint.match(/([0-9]+)/)[0]; // keine Ahnung warum aber hierbei kommt immer ein Array mit 2 Treffern zurück

                                // wir gehen davon aus, dass die id beim patch immer am Ende der URL steht.
                                e.url = appConfig.apiHost + config['deleteUrl'] + id;
                            }
                            break;
                    }
                },
                errorHandler: (e) => {
                    if(e.httpStatus === 401)
                    {
                        // @todo translate
                        notify('Ihre Sitzung ist aufgrund zu langer nicht-Nutzung abgelaufen und Sie wurden daher zur Sicherheit automatisch abgemeldet.',
                            "Error", 5000);
                        auth.logOut().then();
                        router.push({
                            path: "/authentication",
                            query: {redirect: "/home"}
                        });
                    }
                    else if(e.httpStatus === 403)
                    {
                        e.hasRight = false;
                    }
                    else
                    {
                        moDebugger.moError('utils/odata-store/storeError', e.httpStatus, e);
                    }
                }
            },
            select: [],
            filter: config.filter
        }
    }
}
